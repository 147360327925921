<template>
  <div
    :class="$style.root"
    ref="rootElement"
    @keyup.esc="$emit('closeSelf')"
    tabindex="0"
  >
    <div
      :class="$style.container"
      ref="contentElement"
    >
      <div :class="$style.top">
        <button
          @click="$emit('closeSelf')"
          :class="$style.button"
        >
          <SvgoCross data-cross />
        </button>
      </div>

      <div :class="$style.content">
        <div :class="$style.inputContainer">
          <input
            v-model="queryInput"
            :class="$style.input"
            :placeholder="$t('search.placeholder')"
            ref="inputElement"
            tabindex="0"
            :disabled="isPending ? '' : null"
          >

          <button
            :class="$style.buttonToClear"
            @click="queryInput = null"
          >
            {{ $t('search.clear') }}
          </button>
        </div>

        <SearchHitsComponent
          :queryString="query"
          @pending="isPending = $event"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { refDebounced } from '@vueuse/core'
const nuxtApp = useNuxtApp()
const route = useRoute()
const emit = defineEmits(['closeSelf'])
const rootElement = ref(null)
const contentElement = ref(null)
const inputElement = ref(null)


const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false
  }
})

const isPending = ref(false)
const path = computed(() => route?.path)
const queryInput = ref(null)

const query = refDebounced(queryInput, 750)

onClickOutside(contentElement, (event) => emit('closeSelf'), {ignore: ['button']})

watch(() => props?.isOpen, (val) => {
  if (val) {
    setTimeout(() => {
      inputElement.value?.focus()
    }, 50)
  } else {
    queryInput.value = null
    query.value = null
  }
})

watch(path, () => {
  emit('closeSelf')
  queryInput.value = null
  query.value = null
})
</script>

<style module lang="scss">
.root {
  position: fixed;
  z-index: var(--z-index--search);

  top: 0;
  left: 0;
  right: 0;

  margin: 0;
  padding: 0;

  max-height: var(--100dvh);
  overflow: scroll;
}

.root:focus {
  outline: none;
}

.root:before {
  transition: all var(--transition--default);

  content: '';

  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.root:not([class*='slide']):before {
  backdrop-filter: var(--backdrop-filter--brightness--dark);
}

.container {
  position: relative;

  padding: calc(var(--unit--vertical) * 1) var(--unit--horizontal) calc(var(--unit--vertical) * 4) var(--unit--horizontal);

  background-color: var(--color--white);
  color: var(--color--black);

  border-bottom-left-radius: var(--border-radius--default);
  border-bottom-right-radius: var(--border-radius--default);

  --line-height--large: calc(var(--font-large--line-height) * 1.15);
  --line-height--default: calc(var(--font-default--line-height) * 1.15);
  @media (max-width: $breakpoint-m) {
    --line-height--large: calc(var(--font-medium--line-height) * 1.15);
    --line-height--default: calc(var(--font-medium--line-height) * 1.15);
  }
}

.top {
  display: flex;
  justify-content: flex-end;

  margin-bottom: calc(var(--unit--vertical) * 1);

  position: sticky;
  top: var(--unit--vertical);
  z-index: 1;
}

.button {
  composes: reset-button button-round from global;
  position: relative;
}

.content {
  composes: container--default from global;
  padding-left: 0;
  padding-right: 0;
}

.inputContainer {
  position: relative;
  display: flex;

  // position: sticky;
  // top: calc(var(--unit--vertical) * 4);
}

.input {
  composes: reset-input font-size-small from global;

  padding: var(--padding--button);
  border: var(--stroke--default) solid;

  border-radius: 9999px;

  flex: 1;

  background-color: var(--color--white)
}

.input:focus {
  border-color: var(--color--blue);
}

.input::placeholder {
  color: var(--color--gray--dark);
}

.buttonToClear {
  composes: reset-button font-size-x-small from global;

  position: absolute;
  right: var(--unit--horizontal);
  top: 50%;
  transform: translateY(-50%);

  color: var(--color--gray--dark)
}
</style>
