<template>
  <li
    v-if="render && label"
    :class="$style.root"
  >
    {{ label }}
  </li>
</template>

<script setup>
const nuxtApp = useNuxtApp()
const { $i18n } = nuxtApp

const props = defineProps({
  indexUid: {
    type: String,
    required: true
  }
})

const render = computed(() => !props?.indexUid?.endsWith('-page') )
const label = getSearchLabel(props?.indexUid)
</script>

<style module>
.root {
  composes: font-size-x-small from global;
  text-transform: uppercase;

  color: var(--color--gray--dark);

  margin-bottom: var(--lh);
}
</style>
