<template>
  <div :class="$style.root">
    <transition name="slide-top">
      <NavigationMenuComponent
        v-show="menuOpen"
        :isOpen="menuOpen"
        @closeSelf="menuOpen = false"
      />
    </transition>

    <transition name="slide-top">
      <SearchComponent
        v-show="searchOpen"
        :isOpen="searchOpen"
        @closeSelf="searchOpen = false"
      />
    </transition>

    <div :class="$style.content">
      <LayoutTagLineComponent :class="$style.tagLine" />

      <NavigationSiteHeaderComponent
        :class="$style.header"
        :menuOpen="menuOpen"
        @onClickToOpenMenu="menuOpen = true"
        :searchOpen="searchOpen"
        @onClickToOpenSearch="searchOpen = true"
      />

      <main :class="$style.main">
        <div :class="$style.page">
          <slot />
        </div>
      </main>

      <FooterComponent :class="$style.footer" />

      <DynamicPageComponent />

      <GtagConsentComponent />
    </div>
  </div>
</template>

<script setup>
  const menuOpen = ref(false)
  const searchOpen= ref(false)
</script>

<style module>
.root {
  min-height: var(--100svh, 100vh);

  display: flex;
  flex-direction: column;
}

.content {
  display: grid;
  grid-template-areas: 'tagline header'
    'main main'
    'footer footer';
  grid-template-columns: 1fr min-content;
  grid-template-rows: auto 1fr auto;

  position: relative;

  flex: 1;
}

.tagLine {
  grid-area: tagline;
  padding: var(--unit--vertical) var(--unit--horizontal);

  position: relative;
}

.header {
  grid-area: header;
}

.main {
  grid-area: main;
}

.page {
  padding-bottom: calc(var(--unit--vertical) * 6);
  /* min-height: calc(var(--100dvh) - var(--unit--vertical) * 2 - var(--font-small--line-height)); */

  display: flex;
  flex-direction: column;
}

.footer {
  grid-area: footer;
}
</style>
