export const getSearchLabel = (indexUid: string) => {
  const nuxtApp = useNuxtApp()
  const { $i18n } = nuxtApp

  switch (indexUid) {
    case 'city-entry':
      return $i18n.t('label.city')
      break
    case 'cluster-entry':
      return $i18n.t('label.cluster')
      break
    case 'event-entry':
      return $i18n.t('label.event')
      break
    case 'news-entry':
      return $i18n.t('label.news')
      break
    case 'participant-entry':
      return $i18n.t('label.participant')
      break
    case 'publications-entry':
      return $i18n.t('label.publication')
    case 'focus-week-entry':
      return $i18n.t('label.focusWeek')
      break
    case 'venue-entry':
      return $i18n.t('label.venue')
      break
      break
    default:
      return ''
      break
  }
}
