import qs from 'qs'

export const queryFooter = () => {
  const query = qs.stringify(
    {
      populate: {
        informations: {
          fields: [
            'i18n_richtext_en',
            'i18n_richtext_es',
            'i18n_richtext_ca',
          ]
        },
        social_media_links: true,
        page_entries: {
          populate: {
            titles: true,
            slugs: true
          }
        },
        grouped_links: {
          populate: {
            labels: true,
            links: {
              populate: {
                ...queryFragmentLinks
              }
            }
          }
        },
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `footer?${query}`
}
