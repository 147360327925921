export const navigationGetMenuItems = (items: array) => {
  if (!items) { return }
  if (items instanceof Array === false) { return }items

  return items.map((item) => {
    if (item?.attributes?.is_group_key && item?.attributes?.title && item?.attributes?.children?.data?.length) {
      return {
        type: 'group',
        level: 1,
        ...item?.attributes,
        children: item?.attributes?.children?.data?.map((childItem) => {
          if (childItem?.attributes?.is_group_key !== true && isValidUrl(childItem?.attributes?.external_url) && childItem?.attributes?.title) {
            return {
              type: 'externalLink',
              level: 2,
              ...childItem?.attributes
            }
          } else if (childItem?.attributes?.is_group_key !== true && navigationLinkItem(childItem?.attributes)) {
            return {
              type: 'internalLink',
              level: 2,
              ...childItem?.attributes
            }
          } else {
            return null
          }
        })?.filter((item) => item !== null)
      }
    } else if (item?.attributes?.is_group_key !== true && item?.attributes?.external_url && item?.attributes?.title) {
      return {
        type: 'externalLink',
        level: 1,
        ...item?.attributes
      }
    } else if (item?.attributes?.is_group_key !== true && navigationLinkItem(item?.attributes)) {
      return {
        type: 'internalLink',
        level: 1,
        ...item?.attributes
      }
    } else {
      return null
    }
  })?.filter((item) => item !== null)
}

const isValidUrl = urlString => {
  var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
  return !!urlPattern.test(urlString);
}
