<template>
  <div :class="$style.root">
    <button
      :class="$style.button"
      @click="$emit('setActiveGroup', title)"
      :data-active="activeGroup === title ? '' : null"
      :data-idle="isIdle ? '' : null"
    >
      <span :class="$style.text">{{ title }}</span> <span :class="$style.arrow">→</span>
    </button>

    <client-only>
      <template v-if="hasChildren && activeGroup === title">
        <Teleport to="#menu-submenu">
          <ul
            :class="$style.list"
            :style="`--index: ${index}`"
          >
            <li>
              <button
                @click="$emit('setActiveGroup', null)"
                :class="$style.buttonToClose"
              >
                <SvgoArrow />
              </button>
            </li>
            <li
              v-for="(item, index) in data?.children"
              :key="index"
            >
              <NavigationMenuItemComponent
                :data="item"
                :includeArrow="false"
              />
            </li>
          </ul>
        </Teleport>
      </template>
    </client-only>
  </div>
</template>

<script setup>
const props = defineProps({
  data: {
    type: Object
  },
  activeGroup: {
    type: String,
    default: null
  },
  index: {
    type: Number,
    default: 0
  }
})

const hasChildren = computed(() => {
  return props?.data?.children?.length > 0
})

const currentLanguageCode = computed(() => baseI18nGetActiveCode())
const title = computed(() => {
  return navigationGetTitle(props?.data, currentLanguageCode.value)
})

const isIdle = computed(() => {
  return props?.activeGroup !== title.value && props?.activeGroup !== null
})
</script>

<style module lang="scss">
.root {
}

.button {
  composes: reset-button from global;

  display: flex;
  justify-content: flex-end;
  gap: .65ch;

  text-align: right;
  margin-left: auto;

  max-width: calc(50vw - var(--unit--horizontal));

  transition: color var(--transition--default);

  @media (max-width: $breakpoint-s) {
    text-align: left;
    margin-left: 0;
    max-width: 100%;
    display: inline-block;
  }
}

.text {
  flex: 1;
}

.arrow {
  transition: transform var(--transition--default);
}

.button:hover .arrow,
.button[data-active] .arrow {
  transform: translateX(50%);
}

.button[data-idle] {
  color: var(--color--blue--light);
}

.list {
  composes: reset-list font-size-default from global;
  margin-top: calc(var(--line-height--large) * var(--index, 0) + (var(--line-height--large) / 2) - (var(--line-height--default) / 2) );

  line-height: var(--line-height--default);

  overflow: hidden;

  @media (max-width: $breakpoint-s) {
    margin-top: 0;
  }
}

.buttonToClose {
  composes: reset-button button-round from global;

  --button--background-color: var(--color--white);
  --button--background-color--hover: var(--color--gray--light);
  --button--stroke: var(--color--black);

  display: none;
  @media (max-width: $breakpoint-s) {
    display: flex;
    margin-bottom: var(--unit--vertical);
  }
}
</style>
