<template>
  <footer
    :class="$style.root"
    ref="root"
  >
    <FooterContentComponent :class="$style.content" />
  </footer>
</template>

<script setup>
const root = ref(null)
const route = useRoute()
const fullPath = computed(() => route.fullPath)
const { isDesktop } = useDevice();

const { height: windowHeight } = useWindowSize()
const { height: rootHeight } = useElementSize(root)
const { y: scrollY } = useWindowScroll()

const setCssProperty = () => {
  if (!isDesktop) return

  const wHeight = process.client && parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--1svh')) ? parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--1svh')) * 100 : windowHeight.value
  const rootHeighWithFallback = rootHeight.value <= wHeight ? rootHeight.value : wHeight
  const elementTop = root?.value?.getBoundingClientRect()?.top - (wHeight - rootHeighWithFallback)

  if (elementTop < rootHeight.value) {
    const revealPercentage = (elementTop / rootHeight.value) * 100
    root?.value?.style?.setProperty('--translate-y', `-${revealPercentage / 2}%`)
  }
}

onMounted(() => {
  setCssProperty()
})

watch([scrollY], (value) => {
  setCssProperty()
}, { immediate: true })

watchDebounced([fullPath], (value) => {
  setCssProperty()
}, { debounce: 10 })
</script>

<style module>
.root {
  overflow: hidden;
}

.content {
  will-change: transform;
}
</style>
