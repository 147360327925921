import qs from 'qs'

export const queryCookieConsent = () => {
  const query = qs.stringify(
    {
      populate: {
        titles: true,
        descriptions: true,
        page_entries: {
          populate: {
            titles: true,
            descriptions: true
          }
        }
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `cookie-consent?${query}`
}
