<template>
  <div :class="$style.root">
    <SvgoLogo15 :class="$style.logo" />

    <div :class="$style.information">
      <CoreTextComponent
        v-if="information"
        :string="information"
        :class="$style.informationText"
      />

      <FooterLinksComponent
        v-if="groupedLinks?.length > 0"
        v-for="group in groupedLinks"
        :group="group"
      />

      <FooterSocialMediaLinksComponent
        v-if="socialMediaLinks?.length > 0"
        :links="socialMediaLinks"
      />
    </div>

    <div :class="$style.partnersContainer">
      <div :class="$style.partnersLabel">{{ $t('footer.partners') }}</div>
      <div :class="$style.partners">
        <SvgoLogoManifesta />
        <SvgoLogoAjuntament />
        <SvgoLogoDiputacio />
        <SvgoLogoGeneralitat />
        <SvgoMiniserioDeCulturaNegatiu />
      </div>
    </div>

    <div :class="$style.footer">
      <div>{{ $t('footer.allRightsReserved', { year: currentYear }) }}</div>
      <FooterPageLinksComponent
        v-if="pageLinks?.length > 0"
        :links="pageLinks"
      />
    </div>
  </div>
</template>

<script setup>
const { data } = await useLazyAsyncData(() => {
  return $fetch('/api/strapi-rest', { query: { path: queryFooter() } })
})

const localizedData = computed(() => {
  return i18nGetLocalizedData(baseStrapiGetAttributes(data.value))
})

const information = computed(() => i18nGetLocalizedString(localizedData?.value?.informations, 'i18n_richtext_en'))

const socialMediaLinks = computed(() => {
  return localizedData?.value?.social_media_links
})

const groupedLinks = computed(() => {
  return localizedData?.value?.grouped_links
})

const pageLinks = computed(() => {
  return localizedData?.value?.page_entries?.data
})

const currentYear = new Date().getFullYear()
</script>

<style module lang=scss>
.root {
  composes: font-size-small from global;

  background-color: var(--color--blue);

  color: var(--color--white);
  --text-decoration-color: var(--color--blue--light);

  padding: calc(var(--unit--vertical) * 2) var(--unit--horizontal) var(--unit--vertical) var(--unit--horizontal);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: calc(var(--unit--vertical) * 2);
}

.logo {
  fill: var(--color--white);
}

.information {
  composes: font-size-x-small from global;

  display: flex;
  gap: calc(var(--unit--horizontal) * 2);
  flex-wrap: wrap;
  @media (max-width: $breakpoint-s) {
    flex-direction: column;
  }

  @media (max-width: $breakpoint-s) {
    grid-template-columns: 1fr 1fr;
  }
}

.informationText {
  padding-right: calc(var(--unit--horizontal) * 2);
}

.partnersLabel {
  composes: font-size-x-small from global;
  width: 100%;
  color: var(--color--blue--light);
  margin-bottom: var(--unit--spacer);
}

.partners {
  display: flex;
  flex-wrap: wrap;
  gap: calc(var(--unit--horizontal) * 2);
}

.partners svg {
  width: 10rem;
  fill: var(--color--white);
  overflow: visible;
}

.partners img {
  width: 10rem;
}

.footer {
  composes: font-size-x-small from global;

  color: var(--color--blue--light);

  display: flex;
  flex-wrap: wrap;
  gap: var(--unit--horizontal);
  justify-content: space-between;
}
</style>
