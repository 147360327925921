<template>
  <ul
    v-if="group?.links?.length > 0"
    :class="$style.root"
  >
    <li :class="$style.label">
      {{ label }}
    </li>
    <li
      v-for="link in group?.links"
      :key="link.id"
    >
      <I18nLinkWithFallback
        v-if="linkObject(link)?.routeReference?.name"
        :name="linkObject(link)?.routeReference?.name"
        :localizedData="linkObject(link)"
        :class="$style.link"
      >
        {{ linkTitle(link) || $t('shared.more') }}
      </I18nLinkWithFallback>
    </li>
  </ul>
</template>

<script setup>
const props = defineProps({
  group: {
    type: Object
  },
})


const label = computed(() => i18nGetLocalizedString(props.group?.labels))

const linkObject = (link) => {
  return navigationLinkItem(link)
}

const linkTitle = (link) => i18nGetLocalizedString(linkObject(link)?.titles)
</script>

<style module>
.root {
  composes: reset-list from global;
}

.label {
  margin-bottom: var(--lh);
}

.link {
  composes: reset-link from global;
  color: var(--color--blue--light);
}

.link:focus {
  outline: none;
}

.link:focus,
.link:hover {
  text-decoration-line: underline;
}
</style>
