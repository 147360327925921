<template>
  <ul
    v-if="links?.length > 0"
    :class="$style.root"
  >
    <li
      v-for="link in links"
      :key="link.id"
    >
      <I18nLinkWithFallback
        name="slug"
        :localizedData="link?.attributes"
        :class="$style.link"
      >
        {{ getLinkTitle(link?.attributes) }}
      </I18nLinkWithFallback>
    </li>
  </ul>
</template>

<script setup>
const props = defineProps({
  links: {
    type: Array
  },
})

const getLinkTitle = (link) => i18nGetLocalizedTitle(link)
</script>

<style module>
.root {
  composes: reset-list from global;
  display: flex;
  flex-wrap: wrap;
  gap: calc(var(--unit--horizontal) * 2);
}

.link {
  composes: reset-link from global;
}

.link:focus {
  outline: none;
}

.link:focus,
.link:hover {
  text-decoration-line: underline;
}
</style>
