<template>
  <div
    :class="$style.root"
    :data-expanded="expanded ? '' : null"
    ref="root"
  >
    <LayoutPillComponent
      @click="expanded = !expanded"
      :class="$style.button"
    >
      {{ currentLocale }} <SvgoCircle :class="$style.circle" />
    </LayoutPillComponent>

    <transition name="slide-top">
      <ul
        v-show="expanded"
        :class="$style.list"
      >
        <li v-for="locale in localesFiltered" :key="locale.code">
          <LayoutPillComponent
            :key="locale.code"
            :to="switchLocalePath(locale.code)"
            :class="$style.link"
            @click="expanded = false"
            componentType="nuxtLink"
          >
            {{ locale.code }} <SvgoCircle v-show="currentLocale === locale.code" :class="$style.circle" />
          </LayoutPillComponent>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script setup>
const root = ref(null)

const { locale: currentLocale, locales } = useI18n()
const switchLocalePath = useSwitchLocalePath()

const expanded = ref(false)

onClickOutside(root, () => {
  expanded.value = false
}, { ignore: ['button', 'a'] })

const props = defineProps({
  isSticky: Boolean
})

const isSticky = computed(() => {
  return props.isSticky
})

watch(isSticky, (newValue) => {
  expanded.value = false
})

const localesFiltered = computed(() => {
  return locales.value.filter((locale) => locale.code !== currentLocale.value)
})
</script>

<style module>
.root {
  position: relative;
}

.item {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: baseline;
}

.button {
  composes: item;
}

/* .root[data-expanded] .button {
  visibility: hidden;
  pointer-events: none;
} */

.list {
  composes: reset-list font-size-x-small from global;

  position: absolute;
  top: calc(var(--lh) + var(--padding--button--top) + var(--padding--button--bottom) + var(--stroke--default) * 4);
  right: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: calc(var(--stroke--default) * 4);
}

.link {
  composes: reset-link from global;
  composes: item;
}

.link[data-current] {
}

.link:hover {
}

.link:not([href]) {
  pointer-events: none;
  opacity: .2;
}

.circle {
  width: .75ch;
  overflow: visible;
  fill: currentColor;
  transform: translateY(-.1em);
}
</style>
