<template>
  <I18nLink
    :to="routeObject"
    :class="$style.root"
    :data-idle="idle ? '' : null"
  >
    <span :class="$style.text">{{ title }}</span> <span v-if="includeArrow">↗</span>
  </I18nLink>
</template>

<script setup>
const props = defineProps({
  data: {
    type: Object
  },
  idle: {
    type: Boolean,
    default: false
  },
  includeArrow: {
    type: Boolean,
    default: true
  }
})

const item = computed(() => {
  return i18nGetLocalizedData(navigationLinkItem(props?.data))
})

const title = computed(() => i18nGetLocalizedTitle(item?.value))

const slug = computed(() => i18nGetLocalizedString(item?.value?.slugs, 'i18n_slug_en') )

const routeObject = computed(() => {
  return baseRouteGetObject(item?.value?.routeReference?.name, slug.value)
})
</script>

<style module lang="scss">
.root {
  composes: reset-link from global;

  transition: color var(--transition--default);

  white-space: nowrap;

  max-width: 100%;

  display: inline-flex;
  gap: .5ch;

  @media (max-width: $breakpoint-s) {
    white-space: normal;
  }
}

.root .text {
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.root:hover,
.root:global(.router-link-exact-active) {
  color: var(--color--white) !important;

  .text {
    text-decoration-line: underline;
    text-decoration-color: var(--color--blue--light);
  }
}

.root:not(:global(.router-link-exact-active))[data-idle] {
  color: var(--color--blue--light);
}
</style>
