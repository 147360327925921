<template>
  <menu
    :class="$style.root"
    ref="rootElement"
    tabindex="0"
    @keyup.esc="$emit('closeSelf')"
  >
    <div
      :class="$style.container"
      ref="contentElement"
    >
      <div :class="$style.top">
        <LayoutTagLineComponent />
        <button
          @click="$emit('closeSelf')"
          :class="$style.button"
        >
          <SvgoCross data-cross />
        </button>
      </div>

     <NavigationMenuContentComponent />
    </div>
  </menu>
</template>

<script setup>
const nuxtApp = useNuxtApp()
const rootElement = ref(null)
const contentElement = ref(null)

const emit = defineEmits(['closeSelf'])

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false
  }
})

onClickOutside(contentElement, () => {
  emit('closeSelf')
}, { ignore: ['button', 'a'] })

nuxtApp.hook('page:start', () => { emit('closeSelf') })

watch(() => props?.isOpen, (val) => {
  if (val) {
    setTimeout(() => {
      rootElement.value?.focus()
    }, 50)
  }
})
</script>

<style module lang="scss">
.root {
  position: fixed;
  z-index: var(--z-index--menu);

  top: 0;
  left: 0;
  right: 0;

  margin: 0;
  padding: 0;

  max-height: var(--100dvh);
  overflow: scroll;
}

.root:focus {
  outline: none;
}

.root:before {
  transition: all var(--transition--default);

  content: '';

  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.root:not([class*='slide']):before {
  backdrop-filter: var(--backdrop-filter--brightness--dark);
}

.container {
  position: relative;

  padding: calc(var(--unit--vertical) * 1) var(--unit--horizontal) calc(var(--unit--vertical) * 4) var(--unit--horizontal);

  background-color: var(--color--blue);
  color: var(--color--white);

  border-bottom-left-radius: var(--border-radius--default);
  border-bottom-right-radius: var(--border-radius--default);

  --line-height--large: calc(var(--font-large--line-height) * 1.15);
  --line-height--default: calc(var(--font-default--line-height) * 1.15);
  @media (max-width: $breakpoint-m) {
    --line-height--large: calc(var(--font-medium--line-height) * 1.15);
    --line-height--default: calc(var(--font-medium--line-height) * 1.15);
  }
}

.top {
  display: grid;
  grid-template-columns: 1fr min-content;
  gap: var(--unit--horizontal);

  margin-bottom: calc(var(--unit--vertical) * 3);

  position: sticky;
  top: var(--unit--vertical);
}

.button {
  composes: reset-button button-round from global;
  --button--background-color: var(--color--white);
  --button--background-color--hover: var(--color--gray--light);
  --button--stroke: var(--color--black);
}
</style>
