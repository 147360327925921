<template>
  <section
    :class="$style.root"
    v-show="consentSetting !== false && consentSetting !== true"
  >
    <div :class="$style.content">
      <h1 v-if="title" :class="$style.title">
        {{ title }}
      </h1>

      <CoreTextComponent
        v-if="description"
        :string="description"
        :key="description"
        :class="$style.description"
      />

      <ul
        v-if="links?.length"
        :class="$style.links"
      >
        <li
          v-for="link in links"
          :key="link.id"
        >
          <I18nLinkWithFallback
            name="slug"
            :localizedData="link?.attributes"
            :class="$style.link"
          >
            {{ getLinkTitle(link?.attributes) }}
          </I18nLinkWithFallback>
        </li>
      </ul>

      <div :class="$style.buttons">
        <layoutPillComponent
          :class="$style.button"
          sizeOption="narrow"
          @click="grantGtagConsent()"
        >
          {{ $t("cookies.accept") }}
        </layoutPillComponent>
        <layoutPillComponent
          :class="$style.button"
          sizeOption="narrow"
          @click="revokeGtagConsent()"
        >
          {{ $t("cookies.revoke") }}
        </layoutPillComponent>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
  // 1
  const { data, error } = await useAsyncData(() =>
    $fetch('/api/strapi-rest', { query: { path: queryCookieConsent() } })
  )

  const localizedData = computed(() => {
    return i18nGetLocalizedData(baseStrapiGetAttributes(data.value))
  })

  const title = computed(() => i18nGetLocalizedTitle(localizedData?.value))

  const description = computed(() => i18nGetLocalizedString(localizedData.value?.descriptions, 'i18n_richtext_en'))

  const links = computed(() => {
    return localizedData.value?.page_entries?.data
  })

  const getLinkTitle = (link) => i18nGetLocalizedTitle(link)

  // 2
  const { gtag, disableAnalytics, enableAnalytics, initialize } = useGtag()
  const consentSetting = useCookie('gtagConsent', { maxAge: 60 * 60 * 24 * 7 })
  const grantGtagConsent = () => {
    initialize()
    consentSetting.value = true
  }

  const revokeGtagConsent = () => {
    disableAnalytics()
    consentSetting.value = false
  }

  onMounted(() => {
    if(consentSetting.value === true) {
      initialize()
    }
  })
</script>

<style module>
.root {
  composes: font-size-small from global;

  position: fixed;
  z-index: var(--z-index--cookie-consent);
  bottom: 0;

  left: 50%;
  transform: translateX(-50%);

  width: var(--container--small);
  max-width: 100%;

  background-color: var(--color--white);
  border-top-left-radius: var(--border-radius--default);
  border-top-right-radius: var(--border-radius--default);

  padding: var(--unit--vertical) var(--unit--horizontal);

  box-shadow: 0 0 var(--unit--vertical) rgba(0,0,0,0.1);
}

.content {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--unit--spacer);
}

.title {
  composes: reset-heading from global;
  color: var(--color--blue);
}

.description {
  composes: font-size-x-small from global;
  color: var(--color--gray--dark);
}

.links {
  composes: font-size-x-small from global;
  composes: reset-list from global;
}

.link {
  text-decoration-color: var(--color--blue--light);
}

.link:hover {
  text-decoration-line: none;
}

.buttons {
  display: flex;
  gap: var(--unit--spacer);
}

</style>
